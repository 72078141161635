import { backend } from "System/system";
import store from "System/mainStore";

// -------------------------------------------------------
// -------------------------------------------------------
export const writeCreditsJSON = (json) => async dispatch => {
  backend("credits", "json", { json: JSON.stringify(json) });
};


// -------------------------------------------------------
// -------------------------------------------------------
export const storeCredits = (credits) => async dispatch => {
  const projects = store.getState().projects;
  const projectid = projects.project._id;

  backend("credits", "save", { projectid, creditsString: JSON.stringify(credits) });
  dispatch({type: "SET_CREDITS", credits});
};

// -------------------------------------------------------
// -------------------------------------------------------
export const loadCredits = () => async dispatch => {
  const projects = store.getState().projects;
  const projectid = projects.project._id;

  const result = await backend("credits", "load", { projectid });
  if(result.data) {
    const credits = result.data.data;
    dispatch({type: "SET_CREDITS", credits});
  }
};
