// -------------------------------------------------------------
// -------------------------------------------------------------
const currencies = [
  {
    currency: "gbp",
    symbol: "£",
  },
  {
    currency: "krw",
    symbol: "₩",
  },
  {
    currency: "eur",
    symbol: "€",
  },
  {
    currency: "cad",
    symbol: "$",
  },
  {
    currency: "chf",
    symbol: "fr",
  },
  {
    currency: "jpy",
    symbol: "¥",
  },
  {
    currency: "sek",
    symbol: "kr",
  },
  {
    currency: "dkk",
    symbol: "kr",
  },
  {
    currency: "pln",
    symbol: "zł",
  },
  {
    currency: "aud",
    symbol: "$",
  },
  {
    currency: "nzd",
    symbol: "$",
  },
  {
    currency: "hkd",
    symbol: "$",
  },
  {
    currency: "nok",
    symbol: "kr",
  },
];

export default currencies;
