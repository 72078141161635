import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import { generateOutputs, updateProjectStatus } from "Redux/frame/actions";
import { updateTimestamp } from "Redux/projects/actions";
// -------------------------------------------------------------
import "./css.css";

// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [building, setBuilding] = useState(false);
  const [year, setYear] = useState(null);
  const [releasing, setReleasing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const projects = useSelector((state) => state.projects);

  if (!projects.project) return null;

  //const lastUpdated = Date.now();
  const { project } = projects;
  const { lastUpdated } = project;

  if (!project.hasFrame) return null;
  console.log(project);
  const uri = "https://api.bademeister-jan.pro:337/frameoutput?datadir=";

  return (
    <div>
      <div class="field is-grouped">
        <div className="control">
          <input
            className="input"
            type="number"
            placeholder="Cut-off year, e.g. 2024"
            value={year}
            onChange={(evt) => setYear(parseInt(evt.target.value))}
          />
        </div>

        <div className="control">
          <button
            className={`button rebuild ${releasing ? "is-loading" : ""}`}
            disabled={year < 1900 || year > 2024}
            onClick={() => {
              setReleasing(true);
              dispatch(
                generateOutputs(year, () => {
                  setReleasing(false);
                })
              );
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-cogs" />
            </span>
            <span>Generate Output</span>
          </button>
        </div>
        <div className="control">
          <div className="select">
            <select
              value={project.projectStatus}
              className="select"
              onChange={(evt) => {
                dispatch(updateProjectStatus(evt.target.value, project._id));
              }}
            >
              <option value={null}>Null</option>
              <option value="in-progress">In Progress</option>
              <option value="normed">Normed</option>
              <option value="published">Published</option>
              <option value="released">Released</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div className="box">
          {lastUpdated ? (
            <div className="updated-box">
              Last updated:&nbsp;
              <div className="tag is-info is-light">
                {new Date(lastUpdated).toLocaleString("en-DE")}
              </div>
            </div>
          ) : null}
          <button
            className={`button is-small ${isUpdating ? "is-loading" : ""}`}
            onClick={() => {
              setIsUpdating(true);
              dispatch(
                updateTimestamp(project._id, () => {
                  setIsUpdating(false);
                })
              );
            }}
          >
            Update Catalog Timestamp
          </button>
        </div>
      </div>
    </div>
  );
};
export default Index;
