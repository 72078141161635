import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Switch from "react-switch";
// -------------------------------------------------------------
import { ClickableHeader } from "../Shared/ClickableHeader/";
import EditableTag from "../Shared/EditableTag";
// -------------------------------------------------------------
import {
  batchSources,
  setSource,
  clearNorming,
  loadNorming,
  acceptAllSources,
} from "Redux/norming/actions";
import {
  classifySingleSource,
  batchClassifySources,
  batchClassifySources2,
} from "Redux/sources/actions";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
import ProgressBar from "Components/ProgressBar";
// -------------------------------------------------------------
import waitbar from "Media/waitbar.gif";
import "./css.css";

const datatype = "source";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [loadingNorming, setLoadingNorming] = useState(false);
  const [predicting, setPredicting] = useState(false);
  const [reviewing, setReviewing] = useState(null);
  const [threshold, setThreshold] = useState(0.9);
  const [lowOnly, setLowOnly] = useState(false);
  const [newColumn, setNewColumn] = useState(null);

  const norming = useSelector((state) => state.norming);
  const { data, predictions, unsynced } = norming[datatype];

  useEffect(() => {
    setLoadingNorming(true);
    dispatch(
      loadNorming(datatype, () => {
        setLoadingNorming(false);
      })
    );
  }, []);

  useEffect(() => {
    const newNewColumn = [];
    for (var ii = 0; ii < data.length; ii++) {
      const row = data[ii];
      newNewColumn[ii] = `pred-${ii}`;
    }
    setNewColumn(newNewColumn);
  }, [data]);

  if (loadingNorming) return <img src={waitbar} />;
  if (!newColumn) return null;

  const onSwitchChange = (checked) => {
    setLowOnly(checked);
  };

  const batchClassify__ = async () => {
    setPredicting(true);

    const tik = Date.now();
    for (let index = 0; index < 100; index++) {
      const inputString = data[index]["Source_SB"];
      const outputString = await classifySingleSource(inputString);
      setNewColumn((prevColumn) => {
        const updatedColumn = [...prevColumn];
        updatedColumn[index] = outputString;
        return updatedColumn;
      });
    }
    const tok = Date.now();
    console.log("Time taken in seconds:", (tok - tik) / 1000);
    setPredicting(false);
  };

  const batchClassify = async () => {
    setPredicting(true);
    await dispatch(batchClassifySources2());
    setPredicting(false);
  };

  return (
    <div>
      <ProgressBar />
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <div
            className={`button is-small ${predicting ? "is-loading" : ""}`}
            onClick={batchClassify}
          >
            <span className="icon is-small">
              <i className="fas fa-network-wired" />
            </span>
            <span>Classify</span>
          </div>
        </td>
        <td>&nbsp;</td>
        <td>
          <div
            className={`button is-small`}
            onClick={() => {
              if (
                window.confirm("Are you sure? This cannot be undone.") == true
              ) {
                dispatch(clearNorming(datatype));
              }
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-trash-alt" />
            </span>
            <span>Clear</span>
          </div>
        </td>
        <td>&nbsp;</td>
        <td>
          <div
            className={`button is-small`}
            onClick={() => {
              if (window.confirm("Are you sure?") == true) {
                dispatch(acceptAllSources(0));
              }
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-check" />
            </span>
            <span>Accept All</span>
          </div>
        </td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td className="vmid">Hide accepted</td>
        <td>&nbsp;</td>
        <td>
          <Switch onChange={onSwitchChange} checked={lowOnly} />
        </td>
      </NormingButtons>

      <MainTable
        norming={norming}
        setReviewing={setReviewing}
        threshold={threshold}
        hideAccepted={lowOnly}
        newColumn={newColumn}
      />
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const Threshold = ({ threshold, setThreshold }) => {
  const [value, setValue] = useState(threshold);
  const onSubmit = (evt) => {
    if (value > 0 && value <= 1) {
      setThreshold(value);
      return;
    }
    setValue(threshold);
  };

  return (
    <input
      className="input is-small"
      type="text"
      placeholder="Set Confidence Threshold"
      value={value}
      onChange={(evt) => {
        setValue(evt.target.value);
      }}
      onKeyPress={(event) => {
        if (!/[0-9\.]/.test(event.key)) {
          event.preventDefault();
        }
      }}
      onBlur={onSubmit}
      onKeyDown={(evt) => {
        if (evt.key === "Enter") onSubmit(evt);
      }}
    />
  );
};
// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ norming, hideAccepted, threshold, newColumn }) => {
  const dispatch = useDispatch();
  const datatype = "source";
  const { source } = norming;
  if (!source.data) return null;
  if (!source.data.length) return null;
  const { data, predictions } = source;

  return (
    <div className="table-wrapper">
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>#</th>
            <ClickableHeader thisKey="Count" datatype={datatype}>
              Cnt
            </ClickableHeader>
            <ClickableHeader thisKey="Source_SB" datatype={datatype}>
              Original Source
            </ClickableHeader>
            <th>Normalized</th>
            <th>Classifier</th>
            <th>Confidence</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => {
            if (hideAccepted && row["Normalized_Source_9LC"] !== "")
              return null;
            const highlights =
              typeof row["Normalized_Source_9LC"] === "string"
                ? row["Normalized_Source_9LC"].split(" ")
                : [];
            return (
              <tr key={`row-${rowindex}`}>
                <td>{rowindex + 1}</td>
                <td>{row["Count"]}</td>
                <td>
                  <Highlight text={row["Source_SB"]} highlights={highlights} />
                </td>
                <td>
                  <EditableTag
                    currentValue={row["Normalized_Source_9LC"]}
                    rowindex={rowindex}
                    className="tag is-info is-light"
                    updateData={setSource}
                  />
                </td>

                <td>
                  {predictions[rowindex] !== -1 ? (
                    <div
                      className={`tag is-primary is-light is-clickable`}
                      onClick={() => {
                        dispatch(setSource(rowindex, predictions[rowindex][0]));
                      }}
                    >
                      {predictions[rowindex][0]}
                    </div>
                  ) : null}
                </td>
                <td>{predictions[rowindex][1]?.toFixed(2)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const Highlight = ({ text, highlights }) => {
  let hits = [];
  for (const highlight of highlights) {
    if (text.indexOf(highlight) !== -1) {
      const ini = text.indexOf(highlight);
      const fin = ini + highlight.length;
      hits.push({ ini, fin });
    }
  }
  hits = hits.sort((a, b) => (a.ini < b.ini ? -1 : 1));
  if (hits.length == 0) return <div className="tag">{text}</div>;
  let mergedHits = [];
  let prevFin;
  for (var ii in hits) {
    if (ii > 0) {
      if (hits[ii - 1].fin > hits[ii].ini) {
        mergedHits.pop();
        mergedHits.push({
          ini: hits[ii - 1].ini,
          fin: hits[ii].fin,
        });
      } else mergedHits.push(hits[ii]);
      prevFin = hits[ii - 1].fin;
    } else mergedHits.push(hits[ii]);
  }

  const frags = [];
  if (mergedHits[0].ini > 0)
    frags.push({ text: text.slice(0, mergedHits[0].ini), type: "regular" });

  for (var ii = 0; ii < mergedHits.length; ii++) {
    const fin = mergedHits[ii].fin;
    const ini = mergedHits[ii].ini;
    frags.push({ text: text.slice(ini, fin), type: "highlight" });
    if (ii < mergedHits.length - 1) {
      frags.push({
        text: text.slice(fin, mergedHits[ii + 1].ini),
        type: "regular",
      });
    }
  }
  if (mergedHits[mergedHits.length - 1].fin < text.length) {
    frags.push({
      text: text.slice(mergedHits[mergedHits.length - 1].fin, text.length),
      type: "regular",
    });
  }
  return (
    <div className="tags has-addons">
      {frags.map((frag, ii) => {
        const colorClass = frag.type === "regular" ? "" : "is-warning";
        return (
          <div key={`frag-${ii}`} className={`tag ${colorClass}`}>
            {frag.text.trim()}
          </div>
        );
      })}
    </div>
  );
};

/*
// -------------------------------------------------------
// -------------------------------------------------------
const PredictedTag = ({ prediction }) => {
  if (prediction === undefined) return null;
  if (prediction === null)
    return (
      <div>
        <img src={running} />
      </div>
    );
  const predictedText = prediction === "X" ? "PR" : "Song";
  return <div className="tag">{predictedText}</div>;
};
// -------------------------------------------------------
// -------------------------------------------------------
const Review = ({ reviewing, onClose, onInc, onDec }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState("");

  useEffect(() => {
    checkPrediction();
  }, [reviewing]);

  const dispatch = useDispatch();

  const norming = useSelector((state) => state.norming);
  const { source } = norming;
  const { data, reviewed, predictions } = source;
  const dataSize = data.length;
  const numberReviewed = reviewed.filter((x) => x === true).length;

  const canDec = reviewing > 0;
  const canInc = reviewing < dataSize - 1;

  const row = data[reviewing];
  const prediction = predictions[reviewing];

  const checkPrediction = () => {
    if (predictions[reviewing] === undefined) {
      dispatch(predictSource(data, reviewing));
    }
  };

  const onApprove = () => {
    dispatch(setPredictionApproval("source", reviewing, true));
    if (canInc) {
      onInc();
    }
  };

  const onEdit = (evt) => {
    setValue(evt.target.value.toUpperCase());
  };

  const onSubmit = (evt) => {
    dispatch(setSource(reviewing, value));
    setEditing(false);
  };

  const isApproved = reviewed[reviewing];

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head convert">
          <div className="review-wrapper">
            <p className="modal-card-title">
              Review ({reviewing}/{dataSize})
            </p>
            <div className="review-progress">
              <progress
                className="convert progress is-primary"
                value={numberReviewed}
                max={source.data.length}
              />
            </div>
            <button className="delete" onClick={onClose} />
          </div>
        </header>
        <section className="modal-card-body">
          <div className="title">
            {row["Source_SB"]}
            <div className="status-wrapper">
              {isApproved ? (
                <div className="tag is-success is-light is-small">
                  <i className="fa fa-check" />
                  &nbsp;Approved
                </div>
              ) : (
                <div className="tag is-danger is-light is-small">
                  <i className="fa fa-times" />
                  &nbsp;Not yet approved
                </div>
              )}
            </div>
          </div>
          <div className="table-container">
            <table className="table is-fullwidth">
              <thead>
                <tr>
                  <th>Classification</th>
                  <th>Predicted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {editing ? (
                      <input
                        className="input"
                        value={value}
                        onChange={onEdit}
                        onKeyDown={(evt) => evt.key === "Enter" && onSubmit()}
                        onBlur={(evt) => setEditing(false)}
                      />
                    ) : (
                      <div
                        className="tag is-info is-light is-clickable"
                        onClick={() => {
                          setValue(row["Normalized_Source_9LC"]);
                          setEditing(true);
                        }}
                      >
                        {row["Normalized_Source_9LC"]}
                      </div>
                    )}
                  </td>
                  <td>
                    {typeof prediction !== "string" ? (
                      <div>
                        <img src={running} />
                      </div>
                    ) : (
                      <div
                        className="tag is-clickable"
                        onClick={() => {
                          dispatch(setSource(reviewing, prediction));
                        }}
                      >
                        {prediction}
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <footer className="modal-card-foot">
          {isApproved ? (
            <div className="footer-buttons">
              <button
                className="button is-pulled-left"
                onClick={onDec}
                disabled={!canDec}
              >
                <span className="icon is-small">
                  <i className="fa fa-chevron-left" />
                </span>
                <span>Previous</span>
              </button>

              <button
                className="button is-pulled-right is-primary"
                onClick={onInc}
              >
                <span>Next</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>
              <button
                className="button is-pulled-left"
                disabled={!canInc}
                onClick={() => {
                  dispatch(setPredictionApproval("pool", reviewing, false));
                }}
              >
                <span>Un-Approve</span>
              </button>
            </div>
          ) : (
            <div className="footer-buttons">
              <button
                className="button is-pulled-left"
                onClick={onDec}
                disabled={!canDec}
              >
                <span className="icon is-small">
                  <i className="fa fa-chevron-left" />
                </span>
                <span>Previous</span>
              </button>
              <button
                className="button is-primary is-pulled-right"
                disabled={!canInc}
                onClick={onApprove}
              >
                <span>Approve</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>

              <button className="button is-pulled-left" onClick={onInc}>
                <span>Next</span>
                <span className="icon is-small">
                  <i className="fa fa-chevron-right" />
                </span>
              </button>
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};
*/
